 // 正式


 //Kz url
 export const kzName = 'H5'



 export const devBaseURL = "https://reg.anjiashan.com/"; //开发环境
 export const proBaseURL = "https://reg.anjiashan.com/"; //打包环境
 export const userAndroid ='https://sbsm.anjiashan.com/dw/com.hbuser_1.0.1.apk'
 export const userIos = 'https://apps.apple.com/cn/app/%E6%BD%AE%E6%B1%87-%E8%B4%AD%E7%89%A9/id6444200870'
 export const storeAndroid = 'https://sbsm.anjiashan.com/dw/com.hbstore_1.0.1.apk'
 export const stroreIos = 'https://apps.apple.com/cn/app/%E8%B6%85%E5%80%BC%E9%80%89%E7%AB%99/id6639588362'
 
export const BASE_URL = devBaseURL

