import React from "react";
import { WithRouter } from "../../conpoment/WithRouter";
import ShareModal from "../../conpoment/ShareModal";
import { wechatGetLogin, wechatGetCode } from "../../service/http";
import "./style.scss";
import { Mask, Toast } from "antd-mobile";
import moment from "moment";
import { sqgjx_app_link } from "../../config/serviceConfig";

class WeChatLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMask: false,
      isAlreadyAsked: false,
      remainSecond: 0,
      wx: null,
      codeToken: "",
      isOver:false
    };
    this.timer = "";
  }

  componentDidMount() {
    this.initSdk();
  }
  initSdk() {}

  showModal = () => {
    let time = 0;
    try {
      time = Number(window.location.href.split("?")[1]);
    } catch (e) {
      console.log(e);
    }
    if (time && time > 0 && moment().valueOf() > time) {
      this.setState({ showMask: true });
      Toast.show({
        icon: "fail",
        content: "二维码已过期",
        duration: 0,
      });
      return;
    }
    const titleImg = require("../../assets/image/xzt/bind_ios_title1.png");
    this.shareModalRef.show("");
  };

  _onOk = async (param) => {
    if(this.state.isOver){
      Toast.show({
        icon: "success",
        content: "已结束绑定",
      });
    }
    document.activeElement.blur();
    let u = window.navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
    let hrefParams = window.location.href.split("?");
    let obj = {};
    if (hrefParams.length == 2) {
      let splitParams = hrefParams[1].split("&");
      if (splitParams.length > 0) {
        splitParams.forEach((e) => {
          let key = e.split("=")[0];
          let vale = e.split("=")[1];
          obj[key] = vale;
        });
      }
    }
    if (!obj.openId) {
      Toast.show({
        icon: "fail",
        content: "请在公众号内打开",
      });
    }
    if (!this.state.codeToken) {
      Toast.show({
        icon: "fail",
        content: "请先获取验证码",
      });
    }
    if (!this.state.codeToken) {
      Toast.show({
        icon: "fail",
        content: "请输入验证码",
      });
    }

    const params = {
      code: param.password,
      token: this.state.codeToken,
      openId: obj.openId,
    };
    try {
      const result = await wechatGetLogin(params);
      const { value: msg, errorCode } = result;
      if (errorCode == "200") {
        Toast.show({
          icon: "success",
          content: "注册成功",
        });
        this.setState({
          isOver:true,
        })
      } else {
        // this.setState({showMask: true})
        Toast.show({
          icon: "fail",
          content: msg,
          // duration: 0
        });
      }
    } catch (error) {}
  };
  get onOk() {
    return this._onOk;
  }

  set onOk(value) {
    this._onOk = value;
  }

  downloadPackage = () => {
    let u = window.navigator.userAgent;
    // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    if (isIOS) {
      if (sqgjx_app_link.ios) {
        window.location.href = sqgjx_app_link.ios;
      } else {
        Toast.show({
          icon: "fail",
          content: "暂无ios下载",
        });
      }
    } else {
      window.location.href = sqgjx_app_link.android;
    }
  };

  getCode = async (phone) => {
    let _phone = phone;
    try {
      _phone = phone.replace(/\s/g, "");
    } catch (e) {}
    if (!_phone) {
      Toast.show({
        icon: "fail",
        content: "请输入手机号",
      });
      return;
    }
    if (isNaN(_phone)) {
      Toast.show({
        icon: "fail",
        content: "请输入正确手机号",
      });
      return;
    }
    const params = {
      phone: _phone,
    };
    const result = await wechatGetCode(params);
    if (result.errorCode === "200") {
      Toast.show({
        icon: "success",
        content: "发送成功",
      });
      this.setState({
        isAlreadyAsked: true,
        remainSecond: 60,
        codeToken: result.value,
      });
      // 开始倒计时
      this.beginTimer();
    } else {
      Toast.show({
        icon: "fail",
        content: result.value,
      });

      this.setState({
        isAlreadyAsked: true,
        remainSecond: 60,
      });
      // 开始倒计时
      this.beginTimer();
    }
  };

  beginTimer = () => {
    this.clearTimer();
    this.timer = setInterval(() => {
      this.setState({ remainSecond: this.state.remainSecond - 1 });
      if (this.state.remainSecond <= 0) {
        this.setState({ isAlreadyAsked: false, remainSecond: 0 });
        // 清除计时器
        this.clearTimer();
      }
    }, 1000);
  };

  clearTimer = () => {
    this.timer && clearInterval(this.timer);
  };

  render() {
    return (
      <div className="shop_register_view">
        <div className="title_view">
          <img
            src={require("../../assets/image/xzt/shop_tools.png")}
            alt={""}
          />
        </div>
        <div className="note_view">
          <img
            className="left_right_img"
            src={require("../../assets/image/xzt/left@3x.png")}
            alt={""}
          />
          <span className="title_center">销量销量涨不停</span>
          <img
            className="left_right_img"
            src={require("../../assets/image/xzt/right@3x.png")}
            alt={""}
          />
        </div>
        <div className="bottom_btn" onClick={this.showModal}>
          <span>我是店主，我要绑定</span>
        </div>
        <ShareModal
          ref={(ref) => (this.shareModalRef = ref)}
          codeText={
            !this.state.isAlreadyAsked
              ? "获取验证码"
              : "剩余" + this.state.remainSecond + "s"
          }
          onOk={this.onOk}
          getCode={this.getCode}
          btnStr={"确认绑定"}
          hideBlock={true}
        />
        <Mask visible={this.state.showMask}></Mask>
      </div>
    );
  }
}

export default WithRouter(WeChatLogin);
