import { loginApi, drawRedPacketApi,registerApi,shopRrgisterApi,userRegisterApi } from "./api";
import service from "./request";


export const login = async (params) => {
    return service.post(`${loginApi}/`,params)
}

export const drawRedPacket = async (params) => {
    return service.post(`${drawRedPacketApi}/`,params)
}

export const register = async (params) => {
    return service.post(`${registerApi}/`,params)
}

export const shopRrgister = async (params) => {
    return service.post(`${shopRrgisterApi}/`,params)
}

export const userRegister = async (params) => {
    return service.post(`${userRegisterApi}/`,params)
}

export const getCodeAdmin = async (params) => {
    return service.post(`lottery-store-login/api/token/getCodeAdmin`,params)
}

export const sendUserCode = async (params) => {
    return service.post(`login/api/user/sendUserCode`,params)
}

export const registAndCode = async (params) => {
    return service.post(`login/api/user/registAndCode`,params)
}

export const wechatGetCode = async (params) => {
    return service.post(`lottery-store-login/admin/sendVerifyCode`,params)
}

export const wechatGetLogin = async (params) => {
    return service.post(`lottery-store-login/admin/signOrLoginInWeChat`,params)
}


