import React from "react";
import { WithRouter } from "../../conpoment/WithRouter";
import "./style.scss";
import { Mask,  } from "antd-mobile";

class WeChatLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.initSdk();
  }
  initSdk() {}


  render() {
    return (
      <div className="shop_register_view">
        {/* <div className="title_view">
          <img
            src={require("../../assets/image/xzt/shop_tools.png")}
            alt={""}
          />
        </div> */}
       
        <div className="top_btn" >
          <span>解绑成功</span>
        </div>
       
      </div>
    );
  }
}

export default WithRouter(WeChatLogout);
