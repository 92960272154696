import {
  devBaseURL,
  proBaseURL,
  userAndroid,
  userIos,
  storeAndroid,
  stroreIos,
} from "./config";

export const BASE_URL =
  process.env.NODE_ENV === "development" ? devBaseURL : proBaseURL;
export const TIMEOUT = 5000;

//用户down
export const gjzx_app_link = {
  android: userAndroid,
  ios: userIos,
};
//店主down
export const sqgjx_app_link = {
  android: storeAndroid,
  ios: stroreIos,
};

export const ticket_app_link = {
  android: "",
  ios: "",
};

export const assistant_app_link = {
  android: "",
  ios: "",
};
